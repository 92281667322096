export const APIkey = '18198873fb2d831663780ce62f0cc649';
export const APIcode = 'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxODE5ODg3M2ZiMmQ4MzE2NjM3ODBjZTYyZjBjYzY0OSIsInN1YiI6IjY0OGFlZTU0YzNjODkxMDBlYjMxMjQyMiIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.GQKM-fvEmpUPfmuFj4Q_mZchMQwX7m5EjhESEVs8Q8c';

export const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    Authorization: `Bearer ${APIcode}`
  }
};
