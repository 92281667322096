export const carouselDeskImage = 'http://image.tmdb.org/t/p/original';
export const originalImagePath = 'http://image.tmdb.org/t/p/w1280';
export const image300Path = 'http://image.tmdb.org/t/p/w300';
export const image342Path = 'http://image.tmdb.org/t/p/w342';
export const image700Path = 'http://image.tmdb.org/t/p/w780';
export const imagePath = 'http://image.tmdb.org/t/p/w500';
export const mediumImagePath = 'http://image.tmdb.org/t/p/w154';
export const image185Path = 'http://image.tmdb.org/t/p/w185';
export const miniImagePath = 'http://image.tmdb.org/t/p/w92';
export const profileImagePath = 'http://image.tmdb.org/t/p/w185';
export const profileH632Path = 'http://image.tmdb.org/t/p/h632';
export const videoPath = 'https://www.youtube-nocookie.com/embed/';
